import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// 导入axios
import axios from 'axios'
Vue.prototype.$http = axios
// 配置请求根路径
axios.defaults.baseURL = '/'
//上传文件
Vue.prototype.$imgFile = '/'+'api/common/upload'
// 配置拦截器
axios.interceptors.request.use(config => {
  config.headers.Token = store.state.token
  return config
})
//定义事件总线
Vue.prototype.$bus = new Vue()
// 在response拦截器中 隐藏进度条
axios.interceptors.response.use(response => {
  // console.log('成功',response)
  if(response.data.code === 0){
    Message.error(response.data.msg)
    return Promise.reject(response);
  }else if(response.data.code === 200){//重新登录
    Message.error(response.data.msg)
    router.replace('/')
    return
  }
  return response
},error =>{
  Message.error('响应错误')
  return Promise.reject(error);
})
//添加图片域名
Vue.prototype.$getimgsrc = function (url){
  let urlk = url;
  let str = RegExp('http');
  let newUrl;
  //通过三元运算符进行判断该图片是否含有http域名，没有就拼接上去http://mendian.changgongwang.cn/
  str.test(urlk) ? newUrl = urlk : newUrl = 'https://aixiaobaojituan.oss-cn-beijing.aliyuncs.com' + urlk;
  const urls = newUrl
   return urls
 }

// 导入全局样式表
import './assets/css/global.css'
// 导入字体图标
import './assets/font/icon.css'
// 手动配置 element-ui
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUI)
// 手机号校验
import {
  Message
} from 'element-ui';
Vue.prototype.validatorPhone = function (value) {

  if (value === '') {
    Message.error('手机号不能为空')
    return
  } else if (!/^1\d{10}$/.test(value)) {
    Message.error('手机号格式错误')
    return
  } else {
    return true
  }

}
//导入全局组件
import empty from "./module/empty.vue";
Vue.component("empty",empty)
//时间过滤器
Vue.filter('timeform',function (createdate,whattime) { 
  if(whattime){
      let date = new Date(parseInt(createdate) * 1000);
      let y = date.getFullYear();
      let m = date.getMonth() + 1;
      m = m < 10 ? "0" + m : m;
      let d = date.getDate();
      d = d < 10 ? "0" + d : d;
      let h = date.getHours();
      h = h < 10 ? "0" + h : h;
      let minute = date.getMinutes();
      let second = date.getSeconds();
      minute = minute < 10 ? "0" + minute : minute;
      second = second < 10 ? "0" + second : second;
      return y+ "-" +m + "-" + d + " "+h + ":" + minute + ":"+second;
    // const date=new Date(parseInt(createdate)*1000); 
    // const year=date.getFullYear();  
    // const month=date.getMonth()+1;
    // const day=date.getDay();    
    // const shi=date.getHours()  
    // const fen=date.getMinutes() 
    // const miao=date.getSeconds() 
    // return year+"-"+month+"-"+day+" "+shi+":"+fen+":"+miao;
  }else{
    let date = new Date(parseInt(createdate) * 1000);
      let y = date.getFullYear();
      let m = date.getMonth() + 1;
      m = m < 10 ? "0" + m : m;
      let d = date.getDate();
      d = d < 10 ? "0" + d : d;
      let h = date.getHours();
      h = h < 10 ? "0" + h : h;
      let minute = date.getMinutes();
      let second = date.getSeconds();
      minute = minute < 10 ? "0" + minute : minute;
      second = second < 10 ? "0" + second : second;
      return y+ "-" +m + "-" + d ;
    // const date=new Date(parseInt(createdate)*1000); 
    // const year=date.getFullYear();  
    // const month=date.getMonth()+1;
    // const day=date.getDay();    
    // const shi=date.getHours()  
    // const fen=date.getMinutes() 
    // const miao=date.getSeconds() 
    // return year+"-"+month+"-"+day;
  }
  

}
)
// 引入拖动视口文件
import VueDragscroll from 'vue-dragscroll'
Vue.use(VueDragscroll)
// 引入echarts图表
// import Echarts from 'echarts'
// Vue.use(Echarts)
// 引入时间格式化文件
import moment from 'moment'; //导入模块
moment.locale('zh-cn'); //设置语言 或 moment.lang('zh-cn'); 
Vue.prototype.$moment = moment; //赋值使用

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

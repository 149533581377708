<template>
    <div>
        <div class="Box">
            <img src="https://mendian.changgongwang.cn/uploads/kaquan/kaquan.png" alt="" class="image">
            <p class="title">{{ title }}</p>
        </div>
    </div>
</template>

<script>
    export default {
        props:{
            title:{
                type:String,
                default:'暂无内容'
            }
        },
        data() {
            return {
                
            }
        },
        
    }
</script>

<style lang="less" scoped>
.Box{
    text-align: center;
    .image{
        height: 200px;
        width: 200px;
    }
    .title{
        color: rgb(192, 196, 204);
        font-size: 20px;
    }
}
</style>